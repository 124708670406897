




























import AstCheckbox from '@components/Checkbox.vue'
import EmailSelect from '@components/EmailSelect.vue'
import SimpleLoading from '@components/SimpleLoading.vue'
import _ from 'lodash'

export default {
	name: 'TeleconsultationsSettingView',
	components: {
		AstCheckbox,
		EmailSelect,
		SimpleLoading,
	},
	data() {
		return {
			isLoadingUser: true,
			userSettings: {
				rejectEmailNotification: false,
				rejectEmailResponseNotification: false,
				rejectEmailAddress: [],
				telemedicineEmailAddress: [],
				emailAddress: [],
			},
		}
	},
	async mounted() {
		this.getUserSettings()
	},
	methods: {
		getEmailAddress() {
			this.userSettings.emailAddress = this.userSettings.rejectEmailAddress.concat(
				this.userSettings.telemedicineEmailAddress
			)
			this.userSettings.emailAddress = _.uniq(this.userSettings.emailAddress)
		},
		async getUserSettings() {
			this.isLoadingUser = true
			try {
				const getUserSetting = name => this.$api.user.getUserSetting(name, 'ReviewSubmission')
				const getTelemedicineUserSetting = name => this.$api.user.getUserSetting(name, 'Telemedicine')
				const getRejectEmailNotification = getUserSetting('RejectEmailNotification').then(
					r => (this.userSettings.rejectEmailNotification = r.data.toString().toLowerCase() === 'true')
				)
				const getRejectEmailAddress = getUserSetting('RejectEmailAddress').then(
					r => r.data && (this.userSettings.rejectEmailAddress = r.data.split(',').filter(x => !!x.trim()))
				)
				const getTelemedicineEmailAddress = getTelemedicineUserSetting('EmailAddress').then(
					r => r.data && (this.userSettings.telemedicineEmailAddress = r.data.split(',').filter(x => !!x.trim()))
				)
				await Promise.all([getRejectEmailNotification, getRejectEmailAddress, getTelemedicineEmailAddress])
				this.getEmailAddress()
			} finally {
				this.isLoadingUser = false
			}
		},
		saveEmailNotification() {
			this.$api.user.setUserSetting(
				'RejectEmailNotification',
				'ReviewSubmission',
				this.userSettings.rejectEmailNotification.toString()
			)
		},
		saveEmailAddress() {
			this.$api.user.setUserSetting('RejectEmailAddress', 'ReviewSubmission', this.userSettings.emailAddress.join(','))
		},
	},
}
